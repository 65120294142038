// import React from 'react';
import styled from 'styled-components';

// TODO: delete this file but first need to remove the component from all the files refer to it
const CommonPageStyles = styled.div`
  /* margin-bottom: 2.5em; */

  /* h2,
  h3,
  h4 {
    font-weight: bold;
  } */

  /* h3 {
    font-size: 1.25em;
  } */

  /* h4 {
    font-size: 1.05em;
    margin-bottom: 1em;
  } */

  /* li {
    h3,
    ul {
      margin-bottom: 0.5em;
    }
  } */

  /* .bold {
    font-weight: bold;
  } */
`;

export default CommonPageStyles;
